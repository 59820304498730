import React from 'react';
import { Button } from 'semantic-ui-react';

import './Launch.css';

interface LaunchProps {
  Launch: () => void;
}

export const LaunchView: React.FC<LaunchProps> = (props: LaunchProps) => {
return (
		<div id="launchContainer">
			<Button style={{padding: 0}} onClick={() => props.Launch()}>
			<img id="Logo" alt="Art Workbench Logo" src="/ArtWorkbenchPureweb.png" />
			</Button>
		</div>
  );
};

